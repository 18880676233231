<template>
  <v-card flat>
    <v-dialog
      v-model="showDeleteAlert"
      max-width="480px"
    >
      <videon-card heading="Delete Alert" showClose="true" @close="showDeleteAlert = false">
        <v-card-text>
          <v-form @submit.prevent="confirmDeleteAlert">
            <p class="subtitle-1 mt-3">
              Are you sure you want to delete this alert?
            </p>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteAlert = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteAlert"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showNewAlert"
      max-width="640px"
    >
      <videon-card heading="Create Alert" showClose="true" @close="showNewAlert = false">
        <v-card-text>
          <v-form @submit.prevent="createAlert">
            <v-select
              v-model="newAlert.name"
              :items="availableAlerts"
              item-text="label"
              item-value="name"
              label="Alert"
              @change="newAlertTypeChange"
              :disabled="!canEdit"
              outlined
              hide-details
            ></v-select>
            
            <div v-if="newAlert.name == 'offline'">
              <v-alert
                class="mt-5"
                type="info"
                outlined
              >
                Device offline alerting can be managed via <a class="text-decoration-underline" @click="$router.push('/alerts/config')">Legacy Alert Settings</a>.
              </v-alert>
            </div>
            <div v-else>
              <v-text-field
                class="mt-5"
                v-model="newAlert.label"
                label="Alert Name"
                :disabled="!canEdit"
                outlined
                hide-details
              ></v-text-field>
            
              <div class="mt-6" v-if="newAlert">
                <generic-alert-document :alert="newAlert" :device_guid="device_guid" :canEdit="canEdit" :isNew="true" @configUpdate="newAlertUpdate" :key="newAlert.alert_guid"/>
              </div>
            
              <div class="text-right">
                <v-btn
                  class="ma-1"
                  color="secondary"
                  @click="showNewAlert = false"
                  text
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="ma-1"
                  color="primary"
                  @click="createAlert"
                  :disabled="newAlertHasValidationErrors"
                >
                  Create Alert
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-card-title>
      Alert Configuration
      <v-spacer/>
      <v-btn
        small
        color="primary"
        outlined
        tooltip="Add Alert"
        @click="newAlertShow()"
        :disabled="!canEdit"
      >
        <v-icon>mdi-plus</v-icon> Create Alert
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-data-table
          :headers="headers"
          :items="documents"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="alert_guid"
          :items-per-page="100"
          show-expand
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        >
          <template
            v-slot:item.alert_guid="{ item }"
          >
            <span :class="(item.new) ? 'text--disabled' : ''">{{ alertName(item) }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="px-8 pt-8 pb-5">
              <generic-alert-document :alert="item" :device_guid="device_guid" :canEdit="canEdit && item.category == 'device'" @configUpdate="configUpdate" @deleteAlert="deleteAlert(item)" :key="item.alert_guid"/>
            </td>
          </template>
          <template
            v-slot:item.config.enabled="{ item }"
          >
            <span :class="(item.config.enabled) ? 'primary--text' : 'text--disabled'">{{ (item.config.enabled) ? 'Yes' : 'No' }}</span>
          </template>
          <template
            v-slot:item.category="{ item }"
          >
            <span class="text-capitalize">{{ item.category }}</span> Alert
          </template>
          <template
            v-slot:item.alert_state="{ item }"
          >
            <span v-if="!item.alert_state" class="text--disabled">Unknown</span>
            <span v-else :class="(item.alert_state && item.alert_state.toLowerCase() == 'ok') ? 'green--text' : 'red--text'" class="text-capitalize">{{ (item.alert_state) ? $helpers.getLabel(item.alert_state) : '' }}</span>
          </template>
        </v-data-table>
      </v-container>
      <div class="text-right" v-if="canEdit">
        <v-btn
          color="secondary"
          text
          class="ma-1"
          @click="revertShadow()"
          :disabled="!hasChanges"
        >
          Revert
        </v-btn>
        <v-btn
          color="primary"
          class="ma-1"
          @click="saveShadow()"
          :disabled="!hasChanges"
        >
          Save
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  import GenericAlertDocument from './documents/GenericAlertDocument.vue'
  
  export default {
    name: 'EdgecasterAlerts',
    
    props: ['deviceShadow', 'device_guid'],
    
    components: {
      GenericAlertDocument
    },
    
    data() {
      return {
        expanded: [],
        
        documents: [],
        
        hasChanges: false,
        expectingChanges: false,
        hasValidationError: false,
        
        showDeleteAlert: false,
        
        selectedAlert: false,
        
        showNewAlert: false,
        
        availableAlerts: [
          {'name': 'thermal_system_temp_current', 'label': 'High Temperature', 'type': 'metric', 'threshold_value': 80, 'threshold_condition': 'GreaterThan', 'notify_on_close': true, 'user_list': [], 'email_list': []},
          {'name': 'memory_system_percent_used', 'label': 'High Memory Utilization', 'type': 'metric', 'threshold_value': 90, 'threshold_condition': 'GreaterThan', 'notify_on_close': true, 'user_list': [], 'email_list': []},
          {'name': 'filesystem_userdata_percent_used', 'label': 'High Filesystem Utilization', 'type': 'metric', 'threshold_value': 90, 'threshold_condition': 'GreaterThan', 'notify_on_close': true, 'user_list': [], 'email_list': []},
          {'name': 'video_input_format', 'label': 'Undefined Input Video Format', 'type': 'inputs', 'threshold_value': 'FORMAT_UNDEFINED', 'threshold_condition': 'EqualTo', 'notify_on_close': true, 'user_list': [], 'email_list': []},
          {'name': 'status_code', 'label': 'Output Error', 'type': 'outputs', 'threshold_value': 'RUNNING', 'threshold_condition': 'NotEqualTo', 'notify_on_close': true, 'user_list': [], 'email_list': []},
          {'name': 'status', 'label': 'Encoder Error', 'type': 'encoders', 'threshold_value': 'RUNNING', 'threshold_condition': 'NotEqualTo', 'notify_on_close': true, 'user_list': [], 'email_list': []},
          {'name': 'offline', label: 'Device Offline'}
        ],
        
        newAlert: false,
        newAlertHasValidationErrors: false,
        
        headers: [
          { text: 'Alert Name', align: 'start', value: 'alert_guid', sortable: false },
          { text: 'Category', align: 'center', value: 'category', sortable: false },
          { text: 'Enabled', align: 'center', value: 'config.enabled', sortable: false },
          { text: 'Alert State', align: 'center', value: 'alert_state', sortable: false },
        ],
        
        refreshTimer: false,
        refreshInterval: 10000
      }
    },
    watch: {
      deviceShadow: {
        handler() {
          // only overwrite if we dont have any documents to start with
          if (this.expectingChanges || this.expanded.length == 0 || this.documents.length == 0) {
            var documents = this.deviceShadow.editableStateCopy()
            if (documents) {
              this.documents = documents
              
              // reset expectingChanges
              setTimeout(() => {
                this.expectingChanges = false
              }, 5000)
            }
          }
        }, 
        deep: true
      }
    },
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      canEdit() {
        if (this.deviceShadow.updatePending) {
          return false
        }
        
        return this.device.canEdit()
      },
    },
    
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
    },
    
    mounted() {
      var documents = this.deviceShadow.editableStateCopy()
      if (documents) {
        this.documents = documents
        this.hasChanges = false
        
        this.refreshShadow()
      }
    },
    
    methods: {
      refreshShadow() {
        if (this.refreshTimer) {
          // dont refresh if you have pending changes...
          if (!this.hasChanges && !this.expectingChanges) {
            this.deviceShadow.fetchShadow()
          }
          
          clearTimeout(this.refreshTimer)
          this.refreshTimer = false
        }
        
        if (this.refreshInterval) {
          this.refreshTimer = setTimeout(() => {
            if (this.refreshTimer) {
              this.refreshShadow()
            }
          }, this.refreshInterval)
        }
      },
      
      alertName(item) {
        if (item.label || item.config.label) {
          return item.label || item.config.label
        } else if (item.name || item.config.name) {
            return item.name || item.config.name
        } else if (item.new) {
          return 'New Alert'
        } else if (item.alert_guid) {
          return 'Alert #' + item.alert_guid
        }
        return 'Alert N'
      },
      
      newAlertShow() {
        this.newAlert = { 
          'alert_guid': this.$helpers.uuidv4(),
          'type': '',
          'name': '',
          'label': '',
          'category': 'device',
          'config': {},
          'new': true
        }
        this.showNewAlert = true
      },
      
      newAlertTypeChange() {
        this.$delete(this.newAlert, 'config')
        
        var alertSkeleton = this.availableAlerts.find(x => x.name == this.newAlert.name)
        if (alertSkeleton) {
          this.newAlert.type = alertSkeleton.type
          this.newAlert.label = alertSkeleton.label
          
          this.newAlert.config = {
            enabled: false,
            threshold_value: alertSkeleton.threshold_value,
            threshold_condition: alertSkeleton.threshold_condition,
            notify_on_close: alertSkeleton.notify_on_close, 
            user_list: alertSkeleton.user_list,
            email_list: alertSkeleton.email_list,
          }
        }
        
        console.log('EdgecasterAlerts newAlertTypeChange', this.newAlert)
      },
      
      newAlertUpdate(config, hasValidationError = false) {
        console.log('EdgecasterAlerts newAlertUpdate', config, hasValidationError)
        this.newAlert.config = config
        this.newAlertHasValidationErrors = hasValidationError
      },
      
      createAlert() {
        if (!this.newAlertHasValidationErrors) {
          console.log('EdgecasterAlerts createAlert', this.newAlert)
          this.documents.push(Object.assign({}, this.newAlert))
          this.hasChanges = true
          this.expanded = []
          
          this.showNewAlert = false
        }
      },
      
      
      deleteAlert(item) {
        this.selectedAlert = item
        this.showDeleteAlert = true
      },
      confirmDeleteAlert() {
        var alertIndex = this.documents.indexOf(this.selectedAlert)
        if (alertIndex !== -1) {
          console.log('EdgecasterAlerts confirmDeleteAlert deleting alert', this.selectedAlert)
          
          this.documents.splice(alertIndex, 1)
          this.hasChanges = true
          this.expanded = []
        }
        this.showDeleteAlert = false
      },
      
      
      configUpdate(config, hasValidationError = false) {
        this.hasChanges = true
        this.hasValidationError = hasValidationError
      },
      
      saveShadow() {
        var state = this.documents.map((alertItem) => {
          delete alertItem.new
          return alertItem
        })
        
        this.deviceShadow.saveShadow(state)
        this.expanded = []
        this.hasChanges = false
        this.expectingChanges = true
      },
      
      revertShadow() {
        this.documents = this.deviceShadow.editableStateCopy()
        this.hasChanges = false
      }
    }
  }
</script>